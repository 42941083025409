.wrapper{
  width: 100%;
  display: flex;
  padding: 40px;
  position: relative;
  flex-direction: column;
}

.box{
 margin-top: 20px;
 margin-bottom: 20px;
 display: flex;
 justify-content: center;
}

.box2{
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  gap: 30px;
  justify-content: center;
  padding-top: 10px;
}

.button{
  font-weight: 400;
  line-height: 18px;
}
