.wrapper {
    position: absolute;
    width: 100%;
    height: calc(100vh - 50px);
    top: 0;
    left: 0;
    background: rgba(255,255,255, 0.5);
    z-index: 10000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box{
    height: calc(100vh - 50px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}