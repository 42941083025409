.wrapper{
 width: 100%;
 display: flex;
 padding: 40px;
 position: relative;
 flex-direction: column;
}
.box{
 margin-bottom: 10px;
 display: flex;
 justify-content: center;
}
