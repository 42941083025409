.wrapper{
  position: fixed;
  width: 100%;
  height: 100vh;
  top:0px;
  left:0px;
  background:rgba(255,255,255, 0.5) ;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapperInner{
  border-radius: 2px;
  position: relative;
}

.boxClose{
  right: 10px;
  top:10px;
  position: absolute;
  padding: 5px;
  cursor: pointer;
  z-index: 10000;
}

