
.close {
   position: absolute;
   right: 5px;
   top: 5px;
   width: 10px;
   height: 0px;
 }
 .closeOne, .closeTwo{
   position: absolute;
   top:0;
   left: 0px;
   height: 23px;
   width: 5px;
   background-color: rgb(117, 11, 34);
 }
 .closeOne {
   transform: rotate(45deg);
 }
 .closeTwo {
   transform: rotate(-45deg);
 }