.wrapper{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  justify-items: center;
  font-size: 12px;

}
.box{
  width: 40px;
  height: 35px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-items: center;
    
}
.span{
  padding: 5px 7px 5px 7px;
  border-radius: 3px;
  height: 26px;
}

.span:hover{
    background-color: #eee;
}