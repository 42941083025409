.boxThree{
  width: 15px;
  height: 15px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.boxFour{
  width: 11px;
   height: 11px;
   border-radius: 2px;
}
