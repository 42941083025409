.wrapper{
    padding: 14px 40px 40px 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    height: 90vh;
   
}
.bar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}