.wrapper{
    display: grid;
    gap:10px
}

.box{
    text-align: center;
}

.p{
    line-height: 18px;
}