.listWrapper {
    width: 100%;
    position: relative;
}

.listWrapper:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    left: 1.2em;
}
