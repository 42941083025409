.wrapperMain{
    display: flex;
    flex-direction: column;
}

.wrapperScreen{
    display: flex;
    cursor: pointer;
    padding: 2px 15px;
}


.wrapperScreenInner{
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-right: 6px;
}

.wrapperScreenInner2{
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    border-radius: 2px;
}

.listBox{
   background-color: rgb(210,212,210);
   width: 14px;
   height: 14px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 2px;
   border-radius: 2px;
  
}

.p{
    font-size: 11px;
    line-height: 19px;
    font-weight: 400;
}