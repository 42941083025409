.wrapper{
    cursor: pointer;
    display: flex;
    align-items: center;
}

.p{
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
}

