
.wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}


.item{
   padding: 0 10px 15px 5px;
}