.wrapper{
    margin: 10px 30px;
    display: flex;
    justify-content: center;
}  

.p{
  font-size: 1rem;
  padding: 5px 25px;
  text-transform: uppercase;
  line-height: 24px;
  text-align: center;
}
