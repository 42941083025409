.section {
   display: flex;
   align-items: center;
   height: 30px;
}

.sectionTwo {
   display: grid;
   grid-template-columns: 50% 50%;
   grid-template-rows: auto;
   align-items: center;
}

.sectionTwoInner {
   display: flex;
   align-items: center;
   height: 30px;
   position: relative;
   z-index: 1;
}

.sectionItemLast {
   justify-content: end;
   height: 100%;
}

.sectionBottom {
   display: flex;
   width: 100%;
}

.wrapperDivider{
   height: 30px;
}