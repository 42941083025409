.wrapper{
  display: block;
  margin: 0 auto;
}

.box{
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  font-size: 12px;
  max-width: 80%;
  margin: 0 auto;
}

.box2{
    margin-right: 5px;
}

.box3{
    justify-self: center;
    display: flex;
    font-weight: bold;
}

.box4{
  cursor: pointer;
  margin-right: 5px;
  font-weight: bold;
}
.box5{
    cursor: pointer;
    margin-left: 5px;
}