.wrapperIcon{
   border-radius: 50%;
   border: 1px solid;
   width: 28px;
   height: 28px;
   position: relative;
   margin-left: 8px;
   margin-right: 8px;
   cursor: pointer;
   box-shadow: 1px 1px 3px black;
}