.wrapperMain{
  padding: 24px;
  display: flex;
  border-radius: 4px;
  border: 1px solid;
  flex-direction: column;

}

.wrapperInner{
 cursor: pointer;

}

.wrapperDivider{
    margin-top: 8px;
    margin-bottom: 8px;
}

.p{
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
}