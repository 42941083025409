.wrapperMain{
  display: flex;
  max-width: 100%;
  width: 100%;

}

.wrapperInner{
    display: flex;
    height: 35px;
    transition: all 0.3s linear;
}