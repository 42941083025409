.wrapper{
  display: grid;
  grid-template-columns: repeat(7,1fr);
  font-weight: bold;
  font-size: 10px;
}


.box{
 width: 19px;
 height: 19px;
 justify-items: center;
 display: flex;
 justify-content: center;
 align-items: center;
 font-weight:  normal;
 font-size: 10px;
 border: 1px solid transparent;
 border-radius: 3px;
 padding: 1px;
 
}

.header{
  margin-bottom: 7px;
}

.day{
    cursor: pointer;
}
.day:hover{
    background-color: #eee;
}


/* .header:hover{
    color:initial;
    background-color: initial;
} */

