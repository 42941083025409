.wrapper{
  position: absolute;
  right: 5px;
  top: 1px;
  border-radius: 50%;
  background-color: gray;
  width: 16px;
  height: 16px;
  opacity: 0.8;
  cursor: pointer;
}

.close {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 6px;
  height: 6px;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: -3px;
  content: ' ';
  top:2px;
  height: 12px;
  width: 2px;
  background-color: #fff;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}