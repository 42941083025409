.wrapper{
    width: 100%;
   position: relative;
   display: flex;
   flex-direction: column;
   padding: 40px;
}

.box{
 margin-top: 20px;
 margin-bottom: 20px;
 display: flex;
 justify-content: center;
}

.box2{
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.button{
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
