.box{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
 }
 .box2{
   display: flex;
   flex-direction: column;
   justify-content: center;
 }
 