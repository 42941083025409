.wrapper{
  margin-left: 13px ;
  margin-right: 13px;
  cursor: pointer;
}

.wrapperInner{
  padding: 5px;
  display: flex;
  justify-content: center;
}

.text{
  text-align: center;
  text-transform:uppercase;
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
}

