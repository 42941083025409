.listModuleWrapper{
    padding-top: 4px;
}

.listModuleWrapperInner{
    cursor: pointer;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: left;
    gap: 4px;
}

.buttonWrapper{
    padding-top: 16px;
}

.buttonText{
    font-size: 12px;
   
}

.p{
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
}

