.wrapper{
    position: relative;
    font-family: 'Roboto';
}

.box{
    position: relative;
    display: flex;
}

.box2{
  display: flex;
  align-items: end;
  margin-left: 5px;
}

.box3{
display: flex;
align-items: center;
}

