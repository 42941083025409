.wrapper{
  position: absolute;
  z-index: 100;
  width: 140px;
  margin-top: 2px;
  border-radius: 3px;
  bottom: -30px;
  background-color: white;
}     

.box{
  padding: 5px 10px;
  background-color: white;
}

