/* ====== ANIM ======= */
@keyframes fade {
    0% {
        width: 0%;
        opacity: 0;
    }

    100% {
        width: 100%;
        opacity: 1;
    }
}

/*====== Commmon ===== */
.parentLevelBox {
    text-transform: uppercase;
    padding-left: 1.3em;
    padding-right: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.parentLevelIconBox {
    min-width: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
}

.childLevelBox {
    position: relative;
    z-index: 0;
    color: #fff;
    padding: 8px;
    cursor: pointer;
}

.childLevelBox:hover {
    background-color: #e6e7e8 !important;
}


.innerChildBox {
    margin-left: 18px;
    text-transform: uppercase;
    font-size: 11px;
    align-items: center;
    display: flex;
    transition: all 0.3s easy-in-out;
}

.menuText {
    font-weight: 600 !important;
    margin-left: 1em !important;
    cursor: pointer !important;
}

.iconWraper {
    display: block;
    margin-right: 7px;
    min-width: 15px;
    width: 15px;
}


/* =======NavMenuList======= */


.p14 {
    font-weight: 600 !important;
    font-size: 14px !important;
}


.wrapperSvgIcon {
    background-color: #fff;
    border: 1px solid;
    border-radius: 99%;
    width: 27px;
    height: 27px;
}


/*=====navMenuUnfold=====*/
.paddingY8 {
    padding: 8px 0;
}

.cursorPointer {
    cursor: pointer;
}

/*=====NavMenuItem=====*/

.menuPosition {
    margin-left: 18px;
    text-transform: uppercase;
    font-size: 11px;
    align-items: center;
    display: flex;
    transition: all 0.3s easy-in-out;
}

.absolute {
    position: absolute
}


.adminIconShow {
    opacity: 0;
    animation: fade 0.2s forwards;
    animation-delay: 0.1s;
    display: block;

}

.adminIconHide {
    display: none;
}

.font13 {
    font-size: 13px;
}

.svgIcon {
    background-color: #fff;
    border: 1px solid;
    border-radius: 99%;
    width: 27px;
    height: 27px;
}