.topContainer {
   width: 100%;
   height: 100%;
}

.sectionBottom {
   display: flex;
   width: 100%;
}

