.wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box{
 display: grid;
 grid-template-columns: auto;
 grid-template-rows: auto;
 gap: 30px;
 justify-content: center;
 padding-top: 10px;
 padding-bottom: 5px;
}
