.wrapper {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}

.wrapperInner {
    display: flex;
    align-items: center;
    height: 30px;
    max-width: 100%;
    overflow: hidden;
}