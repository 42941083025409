
.wrapperInner{
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
   }  
   
   .boxOne{
     display: grid;
     grid-template-columns: auto auto auto;
     grid-template-rows: auto;
     gap: 4px;
     padding: 5px 10px;
     justify-content: left;
   }
   
   .boxTwo{
      width: 15px;
      height: 15px;
      border-radius: 2px;
   }
   
   .boxThree{
     width: 15px;
     height: 15px;
     border-radius: 2px;
     display: flex;
     justify-content: center;
     align-items: center;
     cursor: pointer;
   }
   
   .boxFour{
       width: 11px;
        height: 11px;
        border-radius: 2px;
   }
   
   .p{
       line-height: 18px;
   }