.box{
  display: flex;
  justify-content: center;
  position: sticky;
  align-items: center;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 40px;
}

.button{
  font-weight: 400;
  line-height: 18px;
}