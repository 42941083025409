.wrapper {
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-width: thin;
}

.wrapper::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
}

.wrapper::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    border-left: 1px solid rgba(0, 0, 0, 0);
    border-right:  1px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

.wrapper::-webkit-scrollbar-track {
    background-color: transparent;
}

.wrapper::-webkit-scrollbar-button:single-button {
    background-color: transparent;
    display: block;
    border-style: solid;
    height: 9px;
    width: 10px;
    cursor: pointer;
}

.wrapper::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent #cecece transparent;
}

.wrapper::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 10px 5px 0 5px;
    border-color: #cecece transparent transparent transparent;
}

.wrapper::-webkit-scrollbar-button:single-button:horizontal:decrement {
    border-width: 5px 10px 5px 0;
    border-color: transparent #cecece transparent transparent;
}

.wrapper::-webkit-scrollbar-button:single-button:horizontal:increment {
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent #cecece;
}

.wrapper::-webkit-scrollbar-corner {
    background: transparent;
}


