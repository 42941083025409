.container{
    position: fixed;
    top:0;
    left:0;
    height: 100vh;
    width: 100%;
    z-index: 1000000;
    background-color: rgba(255,255,255,0.5);
}

.div{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    transform: translate(-50%, -50%);
    justify-content: center;
    font-size: 14px;
    border-radius: 10px;
    background-color: rgba(255,255,255,1);
    display: flex;
    justify-content: left;
    height: 22px;
    -webkit-box-shadow: 7px 7px 8px -5px rgba(66, 68, 90, 1);
    -moz-box-shadow: 7px 7px 8px -5px rgba(66, 68, 90, 1);
    box-shadow: 7px 7px 8px -5px rgba(66, 68, 90, 1);
}

.belt{
    color:white;
    border-radius: 10px;
    padding: 3px;
    text-align: center;
    justify-content: center;
    display: flex;
}

.p{
 position: absolute;
 left:50%;
 transform: translate(-50%, 0);
 text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}