.wrapper{
  padding-left: 10px;
}

.wrapperInner {
    display: flex;
    align-items: center;
}

.wrapperLoop {
    line-height: 1rem;
    padding-right: 5px;
    cursor: pointer;
}

.span{
    cursor: pointer;
}