.wrapper{
  margin-left: 0px;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  font-size: 10px;
  justify-items: center;
}
.box{
  width: 40px;
  height: 25px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-items: center;
}

.span{
    padding: 3px 7px 2px 7px;
    border-radius: 3px;
    height: 19px;
  }
  
  .span:hover{
      background-color: #eee;
  }