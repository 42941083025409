.wrapperIcon {
    padding: 3px 0px;
    
}
.wrapperText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
}

.wrapperTextInner {
  display: flex;
  justify-content: left;
}

.text{
   display: flex;
   margin-left: 3px;
   align-items: center;
   cursor: pointer;
   font-size: 10px;
   line-height: 13.6px;
   font-weight: 400;
}